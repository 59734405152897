<template>
  <div
    :class="{
      'container-fluid px-lg-5 mb-3': true,
      'margin-top-0421': this.$route.query.ismobileapp == undefined,
    }"
  >
    <div class="row">
      <div class="col-md-6 d-flex order-2 order-md-1">
        <div class="align-self-center text-center text-md-start">
          <h3 class="text-primary">
            <i class="fa-handshake fas text-secondary rounded-circle"></i>
            Trepr Partner Introduction
          </h3>
          <div class="lead text-justify">
            Trepr (Trip Representative) is the first ONLINE P2P TRANSPORTATION PLATFORM and a
            trusted community marketplace for people to list, discover, and assign air travelers for
            their transportation needs around the world. Trepr connects people all over the world to
            exchange their travel plan and invoke travel services such as flight companionship,
            courier delivery, and shopping concierge, safely, cheaply and promptly respectively
            anywhere in the world by coordinating with travelers who travel in the same route. By
            registering with Trepr, you will be able to refer customers and earn referral rewards
            for every qualified leads. Trepr will also help drive traffic to your platform with huge
            saving on flight bookings and reward your customers with cash back options. We have
            several agents already working with Trepr to take their travel business to new heights.
            So, what are you waiting for? To get your agency registered, fill the form below and in
            case of any further questions, feel free to contact our team to discover how you can
            grow your business with us.
          </div>
        </div>
      </div>
      <div class="col-md-6 order-1 order-md-2">
        <div class="row text-center">
          <div class="col-6 bg-primary text-white">
            <h1>WHAT DO WE PROVIDE?</h1>
            <ul class="list-unstyled">
              <li>Travel companionship</li>
              <li>Courier delivery</li>
              <li>Shopping concierge</li>
              <li>All via flight passengers</li>
            </ul>
          </div>
          <div class="col-6">
            <h1 class="text-secondary">WHY DOES THIS MATTER</h1>
            <ul class="list-unstyled">
              <li>Reliable flight assistance for ease of travel</li>
              <li>Low-cost and guaranteed</li>
              <li>Delivery of unavailable or expensive products from overseas</li>
            </ul>
          </div>
        </div>
        <div class="row bg-info pt-1">
          <div class="col-8">
            <h3 class="text-secondary">Benefits to Agency</h3>
            <ul class="list-unstyled text-white">
              <li>Refer more bookings to agency</li>
              <li>Percentage cut on every match made</li>
              <li>Marketing benefits such as cash back</li>
              <li>Referral reward for shared itineraries</li>
            </ul>
          </div>
          <div class="col-4">
            <img src="/img/partner/parner2.webp" alt="Benefits to Agency" class="img-fluid" />
          </div>
        </div>
        <div class="row bg-secondary">
          <div class="col-4">
            <img
              src="/img/partner/consumer.jpg"
              alt="Benefits to customer"
              class="img-fluid mt-3"
            />
          </div>
          <div class="col-8 text-end">
            <h3 class="text-primary">Benefits to customer</h3>
            <ul class="list-unstyled text-white">
              <li>Earn back up to 75% of light expenses by becoming travellers</li>
              <li>A trusted marketplace for seekers and providers</li>
              <li>Safe companionship for overseas travel</li>
              <li>Cheap courier and prompt concierge services</li>
              <li>Insurance on products and packages shipped</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="agencyGstDetailsWrapper" id="agencyGstDetailsWrapper">
          <h1 class="text-center text-info">Agency GST details</h1>
          <ErrorFormField :msgArray="errors.common" v-if="errors.common !== undefined" />
          <div class="row">
            <div class="form-floating mb-3 col-lg-3 col-md-6">
              <input
                type="text"
                class="form-control"
                id="gstAgencyName"
                placeholder="Agency Name"
                maxlength="20"
              />
              <label for="gstAgencyName">Agency Name</label>
            </div>
            <div class="form-floating mb-3 col-lg-3 col-md-6">
              <select
                class="form-select"
                id="gstAgencyClassification"
                aria-label="gstAgencyClassification"
              >
                <option selected></option>
                <option value="5">Unregistered</option>
                <option value="8">Registered</option>
                <option value="10">AppliedFor</option>
              </select>
              <label for="gstAgencyClassification" class="label-require">
                Agency Classification
              </label>
              <ErrorFormField
                :msgArray="errors.gstAgencyClassification"
                v-if="errors.gstAgencyClassification !== undefined"
              />
            </div>
            <div class="form-floating mb-3 col-lg-3 col-md-6">
              <input
                type="text"
                class="form-control"
                id="gstAgencyGSTIN"
                placeholder="Agency GSTIN"
                maxlength="20"
              />
              <label for="gstAgencyGSTIN">Agency GSTIN</label>
            </div>
            <div class="form-floating mb-3 col-lg-3 col-md-6">
              <select class="form-select" id="gstSupplyType" aria-label="gstState">
                <option value="1">TAX</option>
                <option value="2">SEZWOP</option>
              </select>
              <label for="gstSupplyType"> Supply Type </label>
            </div>
          </div>
          <div class="row">
            <div class="form-floating mb-3 col-lg-3 col-md-6">
              <input
                type="text"
                class="form-control"
                id="gstContactPerson"
                placeholder="Contact Person"
                maxlength="20"
              />
              <label for="gstContactPerson">Contact Person</label>
            </div>
            <div class="form-floating mb-3 col-lg-3 col-md-6">
              <input
                type="text"
                class="form-control"
                id="gstMobileNumber"
                placeholder="Mobile Number"
                maxlength="20"
              />
              <label for="gstMobileNumber"> Mobile Number </label>
            </div>
            <div class="form-floating mb-3 col-lg-3 col-md-6">
              <input
                type="text"
                class="form-control"
                id="gstPhoneNumber"
                placeholder="Mobile Number"
                maxlength="20"
              />
              <label for="gstPhoneNumber"> Phone Number </label>
            </div>
            <div class="form-floating mb-3 col-lg-3 col-md-6">
              <input
                type="text"
                class="form-control"
                id="gstPINCode"
                placeholder="PIN Code"
                maxlength="20"
              />
              <label for="gstPINCode" class="label-require">PIN Code</label>
              <ErrorFormField
                :msgArray="errors.gstPINCode"
                v-if="errors.gstPINCode !== undefined"
              />
            </div>
          </div>
          <div class="row">
            <div class="form-floating mb-3 col-lg-3 col-md-6">
              <input
                type="text"
                class="form-control"
                id="gstEmailId"
                placeholder="Email Id "
                maxlength="255"
              />
              <label for="gstEmailId"> Email Id </label>
            </div>

            <div class="form-floating mb-3 col-lg-3 col-md-6">
              <input
                type="text"
                class="form-control"
                id="gstCorrespondenceMailID"
                placeholder="Mobile Number"
                maxlength="255"
              />
              <label for="gstCorrespondenceMailID"> Correspondence Mail ID </label>
            </div>
            <div class="form-floating mb-3 col-lg-3 col-md-6">
              <input
                type="text"
                class="form-control"
                id="gstRegistrationStatus"
                placeholder="GST Registration Status"
                maxlength="20"
              />
              <label for="gstRegistrationStatus"> GST Registration Status </label>
            </div>
            <div class="form-floating mb-3 col-lg-3 col-md-6">
              <input
                type="text"
                class="form-control"
                id="gstHSNSACCode"
                placeholder="HSN/SAC Code"
                maxlength="20"
              />
              <label for="gstHSNSACCode">HSN/SAC Code</label>
            </div>
          </div>
          <div class="row">
            <div class="form-floating mb-3 col-lg-3 col-md-6">
              <select
                class="form-select"
                id="gstState"
                aria-label="gstState"
                @change="gstStateChange"
              >
                <option selected></option>
                <option value="1">Andaman and Nicobar Islands</option>
                <option value="2">Andhra Prgstesh</option>
                <option value="96">Andhra Prgstesh (New)</option>
                <option value="3">Arunachal Prgstesh</option>
                <option value="4">Assam</option>
                <option value="5">Bihar</option>
                <option value="6">Chandigarh</option>
                <option value="7">Chhattisgarh</option>
                <option value="8">Dgstra and Nagar Haveli</option>
                <option value="9">Daman and Diu</option>
                <option value="10">Delhi</option>
                <option value="11">Goa</option>
                <option value="12">Gujarat</option>
                <option value="13">Haryana</option>
                <option value="14">Himachal Prgstesh</option>
                <option value="15">Jammu and Kashmir</option>
                <option value="16">Jharkhand</option>
                <option value="17">Karnataka</option>
                <option value="18">Kerala</option>
                <option value="97">Lgstakh</option>
                <option value="19">Lakshgstweep</option>
                <option value="20">Mgsthya Prgstesh</option>
                <option value="21">Maharashtra</option>
                <option value="22">Manipur</option>
                <option value="23">Meghalaya</option>
                <option value="24">Mizoram</option>
                <option value="25">Nagaland</option>
                <option value="26">Orissa</option>
                <option value="27">Pondicherry</option>
                <option value="28">Punjab</option>
                <option value="29">Rajasthan</option>
                <option value="30">Sikkim</option>
                <option value="31">Tamil Ngstu</option>
                <option value="95">Telangana</option>
                <option value="32">Tripura</option>
                <option value="34">Uttar Prgstesh</option>
                <option value="33">Uttarakhand</option>
                <option value="35">West Bengal</option>
              </select>
              <label for="gstState" class="label-require"> State </label>
              <ErrorFormField :msgArray="errors.gstState" v-if="errors.gstState !== undefined" />
            </div>
            <div class="form-floating mb-3 col-lg-3 col-md-6">
              <select
                class="form-select"
                id="gstStateCode"
                aria-label="gstState"
                @change="gstStateCodeChange"
              >
                <option selected></option>
                <option value="1">35</option>
                <option value="2">28</option>
                <option value="96">37</option>
                <option value="3">12</option>
                <option value="4">18</option>
                <option value="5">10</option>
                <option value="6">4</option>
                <option value="7">22</option>
                <option value="8">26</option>
                <option value="9">25</option>
                <option value="10">7</option>
                <option value="11">30</option>
                <option value="12">24</option>
                <option value="13">6</option>
                <option value="14">2</option>
                <option value="15">1</option>
                <option value="16">20</option>
                <option value="17">29</option>
                <option value="18">32</option>
                <option value="97">38</option>
                <option value="19">31</option>
                <option value="20">23</option>
                <option value="21">27</option>
                <option value="22">14</option>
                <option value="23">17</option>
                <option value="24">15</option>
                <option value="25">13</option>
                <option value="26">21</option>
                <option value="27">34</option>
                <option value="28">3</option>
                <option value="29">8</option>
                <option value="30">11</option>
                <option value="31">33</option>
                <option value="95">36</option>
                <option value="32">16</option>
                <option value="34">9</option>
                <option value="33">5</option>
                <option value="35">19</option>
              </select>
              <label for="gstStateCode" class="label-require"> State Code</label>
              <ErrorFormField
                :msgArray="errors.gstStateCode"
                v-if="errors.gstStateCode !== undefined"
              />
            </div>
            <div class="form-floating mb-3 col-lg-3 col-md-6">
              <input
                type="text"
                class="form-control"
                id="gstAddressLine1"
                placeholder="Address Line 1"
                maxlength="20"
              />
              <label for="gstAddressLine1" class="label-require">Address Line 1</label>
              <ErrorFormField
                :msgArray="errors.gstAddressLine1"
                v-if="errors.gstAddressLine1 !== undefined"
              />
            </div>
            <div class="form-floating mb-3 col-lg-3 col-md-6">
              <input
                type="text"
                class="form-control"
                id="gstAddressLine2"
                placeholder="Address Line 2"
                maxlength="20"
              />
              <label for="gstAddressLine2">Address Line 2(optional)</label>
            </div>
          </div>
          <div class="row">
            <div class="form-floating mb-3 col-lg-3 col-md-6">
              <select class="form-select" id="gstAgencyCity" aria-label="gstAgencyCity">
                <option selected></option>
                <option v-for="(item, index) in $data.cityList" :value="item.no" :key="index">
                  {{ item.name }}
                </option>
              </select>
              <label for="gstAgencyCity" class="label-require"> Agency City</label>
              <ErrorFormField
                :msgArray="errors.gstAgencyCity"
                v-if="errors.gstAgencyCity !== undefined"
              />
            </div>
            <div class="form-floating mb-3 col-lg-3 col-md-6">
              <select class="form-select" id="gstCompositionLevy" aria-label="gstCompositionLevy">
                <option value="1">Yes</option>
                <option value="0">No</option>
              </select>
              <label for="gstCompositionLevy"> Composition Levy as per Section 10 of CGST </label>
            </div>
            <div class="form-floating mb-3 col-lg-3 col-md-6">
              <input
                type="text"
                class="form-control"
                id="gstProvisionalGSTNo"
                placeholder="Provisional GST No"
                maxlength="20"
              />
              <label for="gstProvisionalGSTNo">Provisional GST No</label>
            </div>
          </div>
          <div class="text-center mt-3">
            <button class="btn btn-primary" @click="save">Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ErrorFormField from '../../common/error/ErrorFormField.vue';
// import LoadingIcons from '../../common/LoadingIcons.vue';
export default {
  name: 'PartnerSignup',
  components: {
    ErrorFormField,
    // LoadingIcons,
  },
  data: function () {
    return {
      errors: {},
      ajaxLoad: {
        next: false,
      },
      countryList: [],
      stateList: [],
      cityList: [],
    };
  },
  watch: {},
  methods: {
    async gstStateChange() {
      let self = this;
      // console.log("state $store ", this.$store.state)
      let apiUrl = process.env.VUE_APP_API_URL;
      var sel = document.getElementById('gstState');
      var value = sel.value;
      document.getElementById('gstStateCode').value = value;
      var text = sel.options[sel.selectedIndex].text;
      await fetch(apiUrl + 'common/get-city', {
        method: 'POST',
        headers: {
          // 'Accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded', // application/json application/x-www-form-urlencoded multipart/form-data; boundary=something
        },
        body: 'stateText=' + encodeURIComponent(text),
      })
        .then((response) => response.json())
        .then((resData) => {
          // console.log('Success:', resData);
          // let messages = resData.messages
          if (resData.status == 'error') {
            alert('error while geting data ' + resData.message);
            return;
          }
          self.$data.cityList = resData.cityList;
        })
        .catch(function (err) {
          console.log('error', err);
        });
      return;
    },
    async gstStateCodeChange() {
      let self = this;
      // console.log("state $store ", this.$store.state)
      let apiUrl = process.env.VUE_APP_API_URL;
      var sel = document.getElementById('gstState');
      document.getElementById('gstState').value = document.getElementById('gstStateCode').value;
      var text = sel.options[sel.selectedIndex].text;
      await fetch(apiUrl + 'common/get-city', {
        // await fetch(apiUrl + 'common/get-city?XDEBUG_SESSION_START=PHPSTORM', {
        method: 'POST',
        headers: {
          // 'Accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded', // application/json application/x-www-form-urlencoded multipart/form-data; boundary=something
        },
        body: 'stateText=' + encodeURIComponent(text),
      })
        .then((response) => response.json())
        .then((resData) => {
          // console.log('Success:', resData);
          // let messages = resData.messages
          if (resData.status == 'error') {
            alert('error while geting data ' + resData.message);
            return;
          }
          self.$data.cityList = resData.cityList;
        })
        .catch(function (err) {
          console.log('error', err);
        });
      return;
    },
    async save() {
      //eslint-disable-next-line
      var data = {};
      this.$data.errors = {};
      var gstAgencyClassification = document.getElementById('gstAgencyClassification').value;
      var gstAgencyClassificationText =
        document.getElementById('gstAgencyClassification').options[
          document.getElementById('gstAgencyClassification').selectedIndex
        ].text;
      var gstPINCode = document.getElementById('gstPINCode').value;
      var gstState = document.getElementById('gstState').value;
      var gstStateText =
        document.getElementById('gstState').options[
          document.getElementById('gstState').selectedIndex
        ].text;
      var gstStateCode = document.getElementById('gstStateCode').value;
      var gstStateCodeText =
        document.getElementById('gstStateCode').options[
          document.getElementById('gstStateCode').selectedIndex
        ].text;
      var gstAddressLine1 = document.getElementById('gstAddressLine1').value;
      var gstAgencyCity = document.getElementById('gstAgencyCity').value;
      var gstAgencyCityText =
        document.getElementById('gstAgencyCity').options[
          document.getElementById('gstAgencyCity').selectedIndex
        ].text;

      if (gstPINCode == '') {
        this.$data.errors.gstPINCode = ['This field is required'];
      } else {
        if (!/^\d+$/.test(gstPINCode)) {
          this.$data.errors.gstPINCode = ['Invalid pin code. Please enter a correct one'];
        }
      }
      if (gstState == '') {
        this.$data.errors.gstState = ['This field is required'];
      }
      if (gstAgencyClassification == '') {
        this.$data.errors.gstAgencyClassification = ['This field is required'];
      }
      if (gstStateCode == '') {
        this.$data.errors.gstStateCode = ['This field is required'];
      }
      if (gstAddressLine1 == '') {
        this.$data.errors.gstAddressLine1 = ['This field is required'];
      }
      if (gstAgencyCity == '') {
        this.$data.errors.gstAgencyCity = ['This field is required'];
      }
      if (
        Object.keys(this.$data.errors).length !== 0 ||
        Object.getPrototypeOf(this.$data.errors) !== Object.prototype
      ) {
        // console.log('test data gst', data);
        // console.log('test (this.$data.errors gst', this.$data.errors);
        return;
      }
      data.gstAgencyName = document.getElementById('gstAgencyName').value;
      data.gstAgencyClassification = gstAgencyClassification;
      data.gstAgencyClassificationText = gstAgencyClassificationText;
      data.gstAgencyGSTIN = document.getElementById('gstAgencyGSTIN').value;
      data.gstSupplyType = document.getElementById('gstSupplyType').value;
      data.gstContactPerson = document.getElementById('gstContactPerson').value;
      data.gstMobileNumber = document.getElementById('gstMobileNumber').value;
      data.gstPhoneNumber = document.getElementById('gstPhoneNumber').value;
      data.gstPINCode = gstPINCode;
      data.gstEmailId = document.getElementById('gstEmailId').value;
      data.gstCorrespondenceMailID = document.getElementById('gstCorrespondenceMailID').value;
      data.gstRegistrationStatus = document.getElementById('gstRegistrationStatus').value;
      data.gstHSNSACCode = document.getElementById('gstHSNSACCode').value;
      data.gstState = gstState;
      data.gstStateText = gstStateText;
      data.gstStateCode = gstStateCode;
      data.gstStateCodeText = gstStateCodeText;
      data.gstAddressLine1 = gstAddressLine1;
      data.gstAddressLine2 = document.getElementById('gstAddressLine2').value;
      data.gstAgencyCity = gstAgencyCity;
      data.gstAgencyCityText = gstAgencyCityText;
      data.gstCompositionLevy = document.getElementById('gstCompositionLevy').value;
      data.gstProvisionalGSTNo = document.getElementById('gstProvisionalGSTNo').value;
      // console.log("state $store ", this.$store.state)
      let self = this;
      let apiUrl = process.env.VUE_APP_API_URL;
      const formData = Object.keys(data)
        .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
        .join('&');
      let token = this.$store.state.token;

      let headersObject = new Headers({
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      });
      await fetch(apiUrl + 'partner/signup-agency-gst', {
        method: 'POST',
        headers: headersObject,
        body: formData,
      })
        .then((response) => response.json())
        .then((resData) => {
          // console.log('Success:', resData);

          if (resData.status == 'error') {
            let messages = resData.messages;
            if (messages.pdMobile != undefined) {
              self.$data.errors.pdMobile = [
                'This  mobile number is existed. Please choose another one',
              ];
              console.log('pdmobile 1111', messages.pdMobile);
              self.$data.errors.pdMobile = messages.pdMobile;
            }
            if (messages.pdEmail != undefined) {
              self.$data.errors.pdEmail = ['This email is existed. Please choose another one'];
              console.log('pdEmail 2222', messages.pdMobile);
              self.$data.errors.pdEmail = messages.pdEmail;
            }

            self.$data.ajaxLoad.normal = false;
            return;
          }

          self.$data.ajaxLoad.normal = false;
          self.$router.push('/');
        })
        .catch(function (err) {
          self.$data.ajaxLoad.normal = false;
          console.log('error', err);
        });
      self.$data.ajaxLoad.normal = false;
    },
  },
  mounted() {},
};
</script>

<style scoped lang="scss">
@import '../../../assets/scss/new/partner/partner_signup.scss';
@import '../../../../node_modules/bs-stepper/dist/css/bs-stepper.min.css';
#bs-stepper-header .step {
  .btn-muted {
    background-color: #eee !important;
  }
  .step-trigger {
    padding: 10px;
  }
}
#bs-stepper-header .active .step-trigger {
  background-color: rgb(41, 115, 115) !important;
  span {
    color: #fff !important;
  }
}
</style>
